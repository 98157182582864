<template>
  <b-container>
    <div v-if="loading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <b-card v-else header="Change password">
      <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
      <b-form>
        <b-form-group id="password" label="New password">
          <b-input type="password" v-model="password"></b-input>
        </b-form-group>
        <b-form-group id="confirm" label="Confirm password">
          <b-input type="password" v-model="confirm"></b-input>
        </b-form-group>
        <b-button variant="primary" type="submit" @click="onSubmit"
          >Change</b-button
        >
        <Back-Button class="ml-2"></Back-Button>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import axios from "axios";
import config from "@/store/config.js";
import { mapGetters } from "vuex";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "changepassword",
  components: {
    BackButton
  },
  data() {
    return {
      password: "",
      confirm: "",
      error: null,
      loading: false
    };
  },
  methods: {
    onSubmit(e) {
      window.scrollTo(0, 0);
      this.loading = true;
      e.preventDefault();
      var self = this;

      if (this.password.length > 0 && this.password === this.confirm) {
        axios
          .post(`${config.AUTH}/changepassword/`, {
            id: this.getUser.id,
            password: this.password
          })
          .then(() => {
            this.$router.push("/profile");
          })
          .catch(e => {
            self.error = e;
            this.loading = false;
          });
      } else {
        this.error = "Passwords don't match";
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters(["getUser"])
  }
};
</script>

<style></style>
